#scrollable {
  height: 8.7in;
  width: 6.2in;
  position: relative;
  padding: 20px;
  overflow: scroll;
}
.visible-print {
  display: block !important;
  width: auto;
  height: auto;
  overflow: visible;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  .header {
    display: none;
  }
  button {
    display: none;
  }
  .navButton {
    display: none;
  }

  .infoIcon {
    display: none;
  }

  @page {
    margin: 30px 0px;
  }
  .container {
    margin: 0px;
  }
  #scrollable {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .visible-print {
    display: block !important;
    height: auto;
    min-width: 8.5in;
    overflow: visible;
    margin: 0px 40px;
  }
  .doc-title {
    text-align: center;
  }
  input {
    border: none;
  }
}
