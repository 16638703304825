@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.static-page {
  /* display: none; */
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#scrollable {
  height: 8.7in;
  width: 6.2in;
  position: relative;
  padding: 20px;
  overflow: scroll;
}
.visible-print {
  display: block !important;
  width: auto;
  height: auto;
  overflow: visible;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  .header {
    display: none;
  }
  button {
    display: none;
  }
  .navButton {
    display: none;
  }

  .infoIcon {
    display: none;
  }

  @page {
    margin: 30px 0px;
  }
  .container {
    margin: 0px;
  }
  #scrollable {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .visible-print {
    display: block !important;
    height: auto;
    min-width: 8.5in;
    overflow: visible;
    margin: 0px 40px;
  }
  .doc-title {
    text-align: center;
  }
  input {
    border: none;
  }
}

